/* eslint-disable react/jsx-no-undef */
import '../../App.css';
import './Index.scss';
import { Input, Message } from '@arco-design/web-react';
import { IconPlusCircle,IconMinusCircle,IconSearch,IconMenuFold,IconMenuUnfold } from '@arco-design/web-react/icon';


import logoImg from '../../../assets/v2/imgs/logo.png'
import shortlogoImg from '../../../assets/v2/imgs/short_logo.png'
import headImg from '../../../assets/v2/imgs/head2x.png'
import AvatarIcon from '../../../assets/v2/imgs/avatar.png'

import { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from "react-router";

import { NavLink,useNavigate } from "react-router-dom";

import LoginDialog from '../LoginDialog';

import {
  useRecoilValue,
  useRecoilState,
} from 'recoil';

import { allCollapseStatus,userInfoState,userInfo as setUser} from '../store'

import {getSetting as ajaxSetting,userInfo as getUser,getRobot,saveSetting as saveSettingApi} from '../../../api/all'


function Header() {
  const loginRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate();
  const [collapseStatus, setCollapseStatus] = useRecoilState(allCollapseStatus)
  const [userInfo, setUserInfo] = useRecoilState(setUser)
  // const userInfo = useRecoilValue(userInfoState)
  const getuserAjax = useCallback(()=>{
    getUser().then((res)=>{
      let user = res.result
      setUserInfo(user)
    })
  },[setUserInfo])

// useEffect(()=>{
//   console.log(userInfo)
//   if(localStorage.getItem('token') && !userInfo?.id){
//     getuserAjax()
//   }
// },[getuserAjax, userInfo])




  return (
    <>
      <div className="top-bar-box zoom" style={{width:location.pathname==='/home'? (collapseStatus?'260px':'90px'):'100%'}}>
        <div className="top-bar">
          <div className="coll-logo">
            <img className={collapseStatus?"logo":"short_logo"} src={collapseStatus?logoImg:shortlogoImg} alt="chatalls" />
            <div className="icon-collapse" onClick={()=>{setCollapseStatus(!collapseStatus)}}>
              {collapseStatus ? <IconMenuFold style={{ fontSize: '22px' }}/>:
              <IconMenuUnfold style={{ fontSize: '22px' }}/>}
            </div>
          </div>
          {
            location.pathname==='/home'?'':
            <div className="login-register">
              {
                userInfo && userInfo.head ? 
                <div className='so_box'>
                  <img className='real_head' src={userInfo.head || AvatarIcon} alt=''/>
                  <div className='setting_out_box'>
                    <div className='zanwei'></div>
                    <div className='setting_out'>
                      <NavLink to={'/premium'} className='txt'>设置</NavLink>
                      <div className='so_border'></div>
                      <div onClick={()=>{
              localStorage.removeItem('token')
              navigate("/")
              window.location.reload(true)
            }} className='txt'>退出</div>
                    </div>
                  </div>
                </div>
                :
                <NavLink onClick={()=>{ loginRef.current.setShowDialog(true)}} className="head-box">
                  <img className="head-img" src={headImg} alt="head" />
                  <span>登录/注册</span>
                </NavLink>
              }
            </div>
          }
        </div>
      </div> 
      <LoginDialog ref={loginRef}/>
    </>
  );
}

export default Header;
