
import React, { Component ,useState, useRef,useEffect} from 'react';
import { Routes, Route,Navigate } from "react-router-dom";
import { useLocation } from "react-router";

import Home from "./pages/main/Home";
import All from "./pages/main/All";
import Model from "./pages/main/Model/Index";
import AllChat from "./pages/main/AllChat/Index";
import Premium from "./pages/main/Premium";
import Setting from "./pages/main/Setting";
import Buy from "./pages/main/Buy";
import Login from "./pages/main/Login";
import Success from "./pages/main/Success";
import LoginDialog from "./pages/main/LoginDialog";


let routes = [
    {
        path: "/",
        component: <Model />,
        exact: true,
    },
    {
        path: "/home",
        component: <Home />,
        exact: true,
    },
    {
        path: "/all",
        component: <All />,
        exact: true,
    },
    {
        path: "/model",
        component: <Model />,
        exact: true,
    },
    {
        path: "/premium",
        component: <Premium />,
        exact: true,
        login:true
    },
    {
        path: "/setting",
        component: <Setting />,
        exact: true,
        login:true
    },
    {
        path: "/buy",
        component: <Buy />,
        exact: true,
        login:true
    },
    {
        path: "/success",
        component: <Success />,
        exact: true
    },
    {
        path: "/login",
        component: <Login />,
        exact: true
    },
    {
        path: "/allchat",
        component: <AllChat />,
        exact: true,
        login:true
    }
]



function Router()  {
    const location = useLocation()
    const loginRef = useRef(null)

    const PrivateRoute = (route) => {
        const token = localStorage.getItem("token");
        return (route.login && !token) ? <Navigate to={route.path} /> : route.component;
        // return route.component
    }; 


    useEffect(() => {
        let idx = routes.findIndex((obj)=>obj.path == location.pathname,)
        let currentRoute = routes[idx]
        let token = localStorage.getItem("token");
        if(currentRoute.login && !token){
            loginRef.current.setShowDialog(true)
        }
         return ()=>{
            
         }
      },[location.pathname]);

    return (
        <>
        <Routes>
            {
                routes.map((route, key) => {
                    if (route.exact) {
                        return <Route key={key} exact path={route.path} element={PrivateRoute(route)} />
                    } else {
                        return <Route key={key} path={route.path} element={PrivateRoute(route)} />
                    }
                })
            }
        </Routes>
        <LoginDialog ref={loginRef}/>
        </>
        
    )
}
export default Router;
