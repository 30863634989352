/* eslint-disable react/jsx-no-undef */
import { NavLink } from "react-router-dom";
import '../../App.css';
import './Index.scss';
import { Image,Modal } from '@arco-design/web-react';
import { useLocation,useNavigate } from "react-router";
import searchIcon from '../../../assets/v2/imgs/search.png'
import searchIcon2 from '../../../assets/v2/imgs/sousuo.png'
import PKIcon from '../../../assets/v2/imgs/pk2x.png'
import PKIcon2 from '../../../assets/v2/imgs/pkgreen.png'
import settingIcon from '../../../assets/v2/imgs/cuxiao@2x.png'
import vipIcon from '../../../assets/v2/imgs/huodong@2x.png'
import allChatIcon from '../../../assets/v2/imgs/allchat.png'
import zhanweiIcon from '../../../assets/v2/imgs/zhanweitu.png'
import VipDialog from "../Dialog/Vip";

import { useState, useEffect, useRef,useCallback } from 'react';
import {
  useRecoilValue,
  useRecoilState
} from 'recoil';

import { allCollapseStatus,userInfo as setUser,robotList as robotListData,settingInfo,chatAllList ,logoMap as logoRobot} from '../store'


import {userInfo as getUser,getRobot,getSetting,getSessions} from '../../../api/all'
let vipType = {
  6:'月度会员',
  7:'年度会员'
}
function Aside() {
  const collapseStatus = useRecoilValue(allCollapseStatus)
  const location = useLocation()
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useRecoilState(setUser)
  const [robotList, setRobotList] = useRecoilState(robotListData)

  const [chatList, setChatList] = useRecoilState(chatAllList)
  const [logoMap,setLogoMap] = useRecoilState(logoRobot)



  const vipDialogRef = useRef(null)

  const gotoVip = ()=>{
    if(localStorage.getItem('token')){
      vipDialogRef.current.setVisible(true)
    }else{
      navigate('./login')
    }
  }

  // 请求用户信息
  const getuserAjax = useCallback(()=>{
    getUser().then((res)=>{
      let user = res.result
      setUserInfo(user)
    })
  },[setUserInfo])


  // 请求机器人信息
  const getRobotAjax = useCallback(()=>{
    getRobot({
      page_size:100,
      page_num:1
    }).then((res)=>{
      setRobotList(res.result.list)
      let obj = {}
      res.result.list.map((item)=>{
        obj[item.id] = item.logo
      })
      setLogoMap(obj)
    })
  },[setLogoMap, setRobotList])

  // 请求前5个聊天
  const getSessionsAjax = useCallback(()=>{
    getSessions({
      page_num:1,
      page_size:5
    }).then((res)=>{
      setChatList(res.result.list)
    }).catch((err)=>{
      console.log(err)
    })
  },[setChatList])

useEffect(()=>{
  if(localStorage.getItem('token') && !userInfo?.id){
    getuserAjax()
    getSessionsAjax()
  }
},[getSessionsAjax, getuserAjax, userInfo])

useEffect(()=>{
  getRobotAjax()
},[getRobotAjax])

const changeUrl = (item)=>{
  return item.robot_id.indexOf(',')>-1 ?  ("/all?cid="+item.id):("/home?cid="+item.id)
}
  
  return (
    <>
    {
      collapseStatus?
      <div className="aside">
        <div className="top-router">
          <NavLink to="/" className="tr-item more-ai">
            <img src={searchIcon} alt='more'/>
            <p>搜索更多AI功能</p>
          </NavLink>

          <NavLink to="/all" className="tr-item pk">
            <img src={PKIcon} alt='pk'/>
            <p >多模型PK</p>
          </NavLink>
        </div>
        <div className="midcontent">
          <div className="chat-history">
            {
              chatList.map((item)=>{
                return (
                  <NavLink to={changeUrl(item)} key={item.id} className="chat-item">
                    <div className='c-logo'>
                      <img src={logoMap[item.robot_id]?'https://chatalls.com/'+logoMap[item.robot_id]:zhanweiIcon} alt='logo'/>
                    </div>
                    <div className='info'>
                      <div className='name-date'>
                          <div className='name'>{item.name}</div>
                          <div className='date'>{item.updated_at.slice(2,10)}</div>
                      </div>
                      <div className='cont'>
                        { JSON.parse(item.chat)[0]?.result }
                      </div>
                    </div>
                  </NavLink>
                )
              })
            }
          </div>
          <div className='func'>
            <NavLink to={'/allchat'} className='fun-item'>
              <img src={ allChatIcon} alt='所有聊天'/>
              <p>所有聊天</p>
            </NavLink>
            <div onClick={()=>{gotoVip()}} className='fun-item'>
              <img src={vipIcon} alt='免费会员'/>
              <p>{vipType[userInfo.vip_id]||'免费会员'}</p>
            </div>
            <NavLink to={'/premium'} className='fun-item'>
              <img src={settingIcon} alt='设置'/>
              <p>设置</p>
            </NavLink>
          </div>
        </div>
        <div className='about-us'>
          <div>关于我们</div>
          <p className='comp-icp'>窄门科技石家庄有限公司<br/>
          冀ICP备2020026849号-5 </p>  
        </div>
      </div>
      :
      <div className="aside short">
        <div className='func'>
          <NavLink to={'/'} className='fun-item'>
            <img src={searchIcon2} alt='搜索AI功能'/>
            <p>搜索AI功能</p>
          </NavLink>
          <NavLink to={'/all'} className='fun-item'>
            <img src={PKIcon2} alt='多模型PK'/>
            <p>多模型PK</p>
          </NavLink>
          <NavLink onClick={()=>{gotoVip()}} className='fun-item'>
            <img src={vipIcon} alt='免费会员'/>
            <p>{vipType[userInfo.vip_id]||'免费会员'}</p>
          </NavLink>
          <NavLink to={'/premium'} className='fun-item'>
            <img src={settingIcon} alt='设置'/>
            <p>设置</p>
          </NavLink>
        </div>
      </div>
      }
      <VipDialog ref={vipDialogRef}/>
    </>
  );
}

export default Aside;
