import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { HashRouter  } from "react-router-dom";
import App from "./App";

ReactDOM?.render(
  // <React.StrictMode>
    <HashRouter >
      <App />
    </HashRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//做性能指标
//webvitals是 国外 用来做性能指标的，lcp fcp之类的，国内我们自己有自己的性能监控方式
reportWebVitals();
