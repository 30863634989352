

import React, { useEffect, useRef } from 'react';
import LoginDialog from './LoginDialog';
// import SelectModel from './Dialog/SelectModel';
// import DelConfirm from './Dialog/DelConfirm';

import './Login.scss'


function Login (){
  const loginRef = useRef(null)
  // const SelectModelRef = useRef(null)
  // const DelConfirmRef = useRef(null)

  useEffect(()=>{
    localStorage.removeItem('token')
    loginRef.current.setShowDialog(true)

    // SelectModelRef.current.setVisible(true)

    // DelConfirmRef.current.setVisible(true)
  },[])

  return (
  <div className='bg-fixed'>
    <LoginDialog ref={loginRef}/>
    {/* <SelectModel ref={SelectModelRef}/> */}
    {/* <DelConfirm ref={DelConfirmRef}/> */}
  </div>
  )
}

export default Login