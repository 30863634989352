import '../../pages/App.css';
import './Home.scss'
import headIcon from '../../assets/v2/imgs/avatar.png'
import GuanbiIcon from '../../assets/v2/imgs/guanbi@2x.png'
import SendIcon from '../../assets/v2/imgs/send.png'
import ThinkIcon from '../../assets/v2/imgs/thinking.png'
import DelIcon from '../../assets/v2/imgs/del_icon.png'
import fuzhiIcon from '../../assets/v2/imgs/fuzhi.png'
import shuaxinIcon from '../../assets/v2/imgs/shuaxin.png'
import dislikeIcon from '../../assets/v2/imgs/undianzan.png'
import dislikeIcon2 from '../../assets/v2/imgs/undianzan2.png'
import zanIcon from '../../assets/v2/imgs/dianzan.png'
import zanIcon2 from '../../assets/v2/imgs/dianzan2.png'

import { IconPlus } from '@arco-design/web-react/icon';
import { Message, Modal, Input } from '@arco-design/web-react';
import {
    useRecoilValue,
    useRecoilState
} from 'recoil';

  

import { getChats, updateChat,getSessions,getSessionsDetail,createChat,postDisLike,postLike} from '../../api/all'
import copy from 'copy-to-clipboard';
import { useNavigate,useLocation } from "react-router-dom"
import queryString from 'query-string';
import { marked } from 'marked'

import axios from "axios";
import { useRef, useEffect, useState ,useCallback } from 'react'
import { chatAllList,robotListState,userInfoState } from './store'

import SelectModel from './Dialog/SelectModel'
import {isEmpty} from '../../utils/tools'
const TextArea = Input.TextArea;
axios.defaults.headers.common['token'] = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');;
// axios.defaults.headers.post['Content-Type'] = 'text/event-stream; charset=utf-8';
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';


function Home() {
    const dialogRef = useRef(null);
    const SelectModelRef = useRef(null);
    const navigate = useNavigate();
    const location  = useLocation();
    const queryParams = queryString.parse(location.search);
    const [controller, setController] = useState(new AbortController());//
    const userInfo = useRecoilValue(userInfoState)//用户信息
    const [sendState, setSendState] = useState(true);//信息发送状态
    const robotList = useRecoilValue(robotListState);//全部机器人列表
    const [robotListFilter, setRobotListFilter] = useState([])//当前聊天机器人列表
    const [robotId, setRobotId] = useState('')//当前聊天机器人id
    const [chatLog,setChatLog] = useState([]) //当前聊天对话内容
    const [chatLogL,setChatLogL] = useState([])//当前聊天左侧信息
    const [chatLogR,setChatLogR] = useState([{},{}])//当前聊天右侧信息
    const [textAreaValue,setTextAreaValue] = useState('')//输入的问题内容
    const [list, setList] = useRecoilState(chatAllList)//左侧5聊天记录
    const [msg,setMsg] = useState(null) //需要push的信息
    const [flagShuaXin,setFlagShuaXin] = useState(0) //是否刷新
    useEffect(() => {
        if(queryParams?.cid){
            getDetail()
        }
    }, [queryParams?.cid])
    

    const getDetail = ()=>{
        getSessionsDetail({id:queryParams?.cid}).then((res)=>{
            setRobotId(res.result.robot_id)
            let cur  = [res.result]
            if(cur[0]){
                let msg = JSON.parse(cur[0].chat)
                let chatLog = []
                msg.map((item,index) => {
                    if(index === 0) {
                        // 问
                        chatLog.push({
                            role: 0,
                            robot:item.robot,
                            date: new Date(),
                            msg: item.user
                        })
                        // 答
                        chatLog.push({
                            ...item,
                            role: 1,
                            robot:item.robot,
                            date: new Date(),
                            msg: item.result,
                            ask: item.user,
                            index:index
                        })
                    }else{ //对比上一个time  相同则不加问
                        if(item.time === msg[index-1].time){//只加答
                             // 答
                            chatLog.push({
                                ...item,
                                role: 1,
                                robot:item.robot,
                                date: new Date(),
                                msg: item.result,
                                ask: item.user,
                                index:index
                            })
                        }else{
                            // 问
                            chatLog.push({
                                role: 0,
                                robot:item.robot,
                                date: new Date(),
                                msg: item.user
                            })
                            // 答
                            chatLog.push({
                                ...item,
                                role: 1,
                                robot:item.robot,
                                date: new Date(),
                                msg: item.result,
                                ask:item.user,
                                index:index
                            })
                        }
                    }
                })
    
                let pointer = 0
                let left = []
                let right = []
                chatLog.map((item,index)=>{
                    if(item.role === 0){
                        // left.push(item)
                        right.push(item)
                        pointer = 0
                    }else{
                        item.seq = pointer++
                        if(pointer%2 === 0){
                            left.push(item)
                        }else{
                            right.push(item)
                        }
                    }
                })
                setChatLog(chatLog)
                setChatLogL(left)
                setChatLogR(right)
            }
        })
    }

    useEffect(() => {
        if(robotId){
            let list = robotList.filter((item)=>{
                return robotId.indexOf(item.id)>-1
            })
            setRobotListFilter(list)
            SelectModelRef.current.setTogetherIds(robotId?.split(",").map((a)=>Number(a)))
        }else{
            let robot_id = []
            robotList.map((item)=>{
                if(item.type===0) {
                    robot_id.push(item.id)
                }
            })
            setRobotId(robot_id.join(','))
        }
    }, [robotId, robotList])
    
    const copyText = (text) => {
        copy(text);
        Message.success('复制成功')
    }

    const sendMsg = () => {
        if (!localStorage.getItem('token')) {
            navigate("/login")
            return
        }
        if (isEmpty(textAreaValue)){
            Message.info('请输入您的问题')
            return
        }
        if(!queryParams?.cid){ //没有cid 新建对话获取cid  再发送聊天
            createChat({
                robot_id:robotId
            }).then((res)=>{
                navigate("/all?cid="+res.result,{ replace: true })
                sendAjax(res.result)
            })
        }else{//有cid  直接发送问题
            sendAjax(queryParams?.cid)
        }
    }

    const sendAjax = (session_id) => {
        setSendState(false)
        let ms = textAreaValue
        let msgObj = {
            role: 0,
            date: Date.now() + '',
            msg: ms,
            position:'R'
        }
        pushMsg(msgObj)
        setTextAreaValue('')
        fetch("https://chatalls.com/chatalls/chat",{
            method: "POST",
            responseType:"stream",
            headers:new Headers(
                {
                    "Content-Type":"text/event-stream",
                    "token": localStorage.getItem("token")
                }
            ),
            signal: controller.signal,
            body: JSON.stringify({
                content:ms,session_id:session_id,robot_id:flagShuaXin!==0?flagShuaXin:undefined
            })
        })
        .then( async (response) => {
            const reader = response.body.getReader();
            let cont = ''
            let flag = 0  //0直接赋值  1删除后赋值
            let position = 'R'
            while(reader){
                const {value, done }= await reader.read();
                if(done){
                    setSendState(true)
                    pushMsg(null)
                    if(chatLog.length<3){
                        getSessionsAjax()
                    }
                    break
                } else {
                    let data = new TextDecoder().decode(value)
                    
                    try{
                        let arr = data.split('\n\n')
                        // eslint-disable-next-line no-loop-func
                        arr.forEach((data) => {
                            if (data) {
                                let a = JSON.parse(data)
                                console.log(a)
                                if(a.result === 'stop'){//stop该模型回答完毕
                                    flag = 0
                                    cont = ''
                                    if(position === 'L'){
                                        position = 'R'
                                    }else{
                                        position = 'L'
                                    }
                                }else{//正常接受数据
                                    cont=a.result
                                    if(flag === 0){
                                        // 直接赋值
                                        pushMsg({
                                            role: 1,
                                            robot:a.robot,
                                            date: Date.now() + '',
                                            msg: cont,
                                            position:position
                                        })
                                        flag = 1
                                    }else{
                                        let filterList = chatLog.filter((item)=>{
                                            return item.role === 1
                                        })
                                        pushMsg({
                                            role: 1,
                                            robot:a.robot,
                                            date: Date.now() + '',
                                            msg: cont,
                                            del:1,
                                            position:position,
                                            index:filterList.length
                                        })
                                    }
                                }
                            }
                        })
                    }catch{
                        Message.info(data)
                    }
                }
            }
        })
        .catch(error => {
            console.error('Fetch error:', error);
        });
    }

    const cancelSend = () => {
        setSendState(true)
        controller.abort()
        setController(new AbortController())
        Message.info('取消请求')
    }
    const getSessionsAjax = useCallback(()=>{
        getSessions({
          page_num:1,
          page_size:5
        }).then((res)=>{
          setList(res.result.list)
        }).catch((err)=>{
          console.log(err)
        })
    },[setList])

    const pushMsg = (msg) => {
       setMsg(msg)
    }

    useEffect(() => {
        if(msg!==null){
            const newArray = [...chatLog];
            let flag = msg.del
            let position = msg.position
            if(msg.del === 1){
                newArray.splice(-1, 1, msg);
            }else{
                newArray.push(msg)
            }
            setChatLog(newArray)
            if(msg.role === 0){
                chatLogR.push(msg)
                setChatLogR(chatLogR)
            }else{
                if(position === 'R'){
                    let right = [...chatLogR];
                    if(msg.del === 1){
                        right.splice(-1, 1, msg)
                    }else{
                        right.push(msg)
                    }
                    setChatLogR(right)
                }else{
                    let left = [...chatLogL];
                    if(msg.del === 1){
                        left.splice(-1, 1, msg);
                    }else{
                        left.push(msg)
                    }
                    setChatLogL(left)
                }
            }
            let $div = document.getElementById('scrollDiv');
            $div.scrollTop = $div.scrollHeight + 200;
        }
    }, [msg]);

    const delModel = ({id}) => {
        if(robotListFilter.length<3){
            return Message.info('多模型至少两个')
        }
        let new_robotId = robotId.split(',').filter((key)=>{
            return id != key
        }).join(",")
        updateModel(new_robotId)
    }

    const updateModel = (ids) => {
        updateChat({
            id:queryParams?.cid,
            robot_id:ids
        }).then(()=>{
            setRobotId(ids)
            SelectModelRef.current.setVisible(false)
        })
    }

    useEffect(() => {
        bindEnter()
    }, [])

    const bindEnter = (e) => {
        if (e && e.keyCode === 13 && e.shiftKey) {

        } else if (e && e.keyCode === 13) {
            // 阻止原生的换行事件
            e.preventDefault();
            // TODO: 发消息
            sendMsg()
        }
    }

    const newChat = (did)=>{
        if(queryParams?.cid){
            navigate("/all",{ replace: true })
            setChatLog([])
            setChatLogL([])
            setChatLogR([])
        }else{
            Message.info('已经是一个新的聊天了')
        }
        return
    }

    useEffect(()=>{
        if(flagShuaXin!==0){
            sendMsg()
            setFlagShuaXin(0)
        }
    },[flagShuaXin, sendMsg])

    const shuXian = (item)=>{
        setTextAreaValue(item.ask);
        setFlagShuaXin(item.robot.id)
    }
    const dianZan = (item)=>{
        postLike({
            session_id:queryParams?.cid,
            index: item.index,
            status: item.like===1?0:1
        }).then(()=>{
            getDetail()
        })
    }
    const daoZan = (item)=>{
        postDisLike({
            session_id:queryParams?.cid,
            index: item.index,
            status: item.dislike===1?0:1
        }).then(()=>{
            getDetail()
        })
    }


    const contTools = (item)=>{
        return (
            <div className='cont_tools'>
                <img className='fuzhi' onClick={()=>{
                    copyText(item.msg)
                }} src={fuzhiIcon} alt='fuzhi'/>
                <img className='shuaxin' onClick={()=>{
                    shuXian(item)
                }} src={shuaxinIcon} alt='fuzhi'/>
                <img className='zan' onClick={()=>{
                    dianZan(item)
                }} src={item.like==1? zanIcon2:zanIcon} alt='fuzhi'/>
                <img className='nozan' onClick={()=>{
                    daoZan(item)
                }} src={item.dislike==1? dislikeIcon2:dislikeIcon} alt='fuzhi'/>
            </div>
        )
    }

    return (
        <>
            <div className="main">
                <div className="duihua" style={{ paddingTop: '20px' }}>
                    <div className="duihua-box">
                        <div id='scrollDiv' className='react-scroll-to-bottom--css-vwhnp-79elbk overflow-auto'>
                            <div className='one_chat'>
                                {
                                    chatLogL && chatLogL.length ? chatLogL.map((item)=>{
                                        return(
                                            <>
                                                {
                                                item.role===0?<div className='ai user_ask flex flex-row-reverse'>
                                                     <div className='flex flex-row-reverse'>
                                                        <div className='touxiang'>
                                                            <img style={{width:'30px'}} src={userInfo?.head || headIcon} alt=''/>
                                                        </div>
                                                        <div className='cont_html_box'>
                                                            <div className='cont_html'>
                                                                <div className="markdown-body markdown-custom-styles !text-base font-normal" dangerouslySetInnerHTML={{ __html: marked(item.msg) }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className='cont_tools flex flex-row-reverse'>
                                                            <img className='fuzhi' onClick={()=>{
                                                                copyText(item.msg)
                                                            }} src={fuzhiIcon} alt='fuzhi'/>
                                                        </div>
                                                    </div> : <div className='ai ai_answer  flex-row'>
                                                            <div className='touxiang'>
                                                                <img style={{width:'30px'}} src={'https://chatalls.com/'+item.robot.logo} alt=''/>
                                                                <p className='ai-name'>{item.robot.name}</p>
                                                            </div>
                                                            <div className='cont_html_box'>
                                                                <div className='cont_html'>
                                                                    <div className="markdown-body markdown-custom-styles !text-base font-normal" dangerouslySetInnerHTML={{ __html: marked(item.msg) }}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {contTools(item)}
                                                        </div>
                                                }
                                            </>
                                        )
                                    }):''
                                }
                                <div style={{height:'1px'}}></div>
                            </div>
                            <div className='one_chat'>
                                {
                                    chatLogR && chatLogR.length ? chatLogR.map((item)=>{
                                        return(
                                            <>
                                                {
                                                item?.role===0?<div className='ai user_ask flex flex-row-reverse'>
                                                    <div className='flex flex-row-reverse'>
                                                        <div className='touxiang'>
                                                            <img style={{width:'30px'}} src={userInfo?.head || headIcon} alt=''/>
                                                        </div>
                                                        <div className='cont_html_box'>
                                                            <div className='cont_html'>
                                                                <div className="markdown-body markdown-custom-styles !text-base font-normal" dangerouslySetInnerHTML={{ __html: marked(item.msg) }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className='cont_tools flex flex-row-reverse'>
                                                            <img className='fuzhi' onClick={()=>{
                                                                copyText(item.msg)
                                                            }} src={fuzhiIcon} alt='fuzhi'/>
                                                        </div>
                                                    </div> : <>{
                                                        item?.role === 1?<div className='ai ai_answer  flex-row'>
                                                        <div className='touxiang'>
                                                            <img style={{width:'30px'}} src={'https://chatalls.com/'+item.robot.logo} alt=''/>
                                                            <p className='ai-name'>{item.robot.name}</p>
                                                        </div>
                                                        <div className='cont_html_box'>
                                                            <div className='cont_html'>
                                                                <div className="markdown-body markdown-custom-styles !text-base font-normal" dangerouslySetInnerHTML={{ __html: marked(item.msg) }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {contTools(item)}
                                                    </div>:''
                                                    }</>
                                                }
                                            </>
                                        )
                                    }):''
                                }
                                <div style={{height:'1px'}}></div>
                            </div>
                        </div>
                        <div className="mb-3 duihua-tools-box">
                            {
                                sendState?'':<div onClick={() => {
                                                    cancelSend()
                                                }} className='stop-gen'>
                                    <img src={GuanbiIcon} alt=""/>
                                    <p>停止生成</p>
                                </div>
                            }
                            <div className="send-input">
                                <div className="textarea-box">
                                    {/* <textarea
                                        disabled={!sendState}
                                        id="myTextarea"
                                        onKeyDown={bindEnter}
                                        className="resize-none overflow-x-hidden overflow-y-auto  w-full outline-none text-sm text-primary-text bg-transparent scrollbar-thin"
                                        autoComplete="off" name="input" placeholder="请输入您的问题 Shift+Enter添加换行"
                                        style={{ height: "40px" }}></textarea> */}

                                        <TextArea
                                            onChange={(value) => {
                                                setTextAreaValue(value)
                                            }}
                                            onKeyDown={bindEnter}
                                            value={textAreaValue}
                                            disabled={!sendState}
                                            id="myTextarea"
                                            placeholder='请输入您的问题'
                                            autoSize={{ minRows: 1, maxRows: 5 }}
                                            style={{ width: '100%' }}
                                        />
                                         {sendState ? <img onClick={() => {
                                            sendMsg()
                                        }} src={SendIcon} alt=""/>:<img className='thinking' src={ThinkIcon} alt=""/>}
                                </div>
                            </div>
                            <div className='duihua-tips'>以上对话内容由人工智能模型输出，仅供您参考，并不代表平台立场。</div>
                        </div>
                    </div>
                </div>

                {/* 右侧新建聊天 选择模型 */}
                <div className='add-model'>
                    <div className='add-mod-top'>
                        <div onClick={()=>{newChat()}} className='add'>
                            {/* <IconPlus style={{ fontSize: '12px', marginRight: '10px' }} /> */}
                            <svg fill="none" stroke="currentColor" strokeWidth="8" viewBox="0 0 48 48" aria-hidden="true" focusable="false" className="arco-icon arco-icon-plus" style={{fontSize: "12px", marginRight: "10px"}}><path d="M5 24h38M24 5v38"></path></svg>
                            新建聊天
                        </div>
                        <div className='d-model'>
                            多模型PK
                        </div>
                        <div className='d-tips'>
                            通过选择多组模型同时进行对话，您可以轻松避免频繁切换账户的麻烦，尽情体验一站式沟通的乐趣！
                        </div>
                    </div>
                    <div className='models-box'>
                        {
                            robotListFilter.map((item) => {
                                return (
                                    <div key={item.id} className='ai-box'>
                                       
                                        <div className='ai-mod'>
                                            <img src={'https://chatalls.com/'+item.logo} alt='' />
                                            <div onClick={()=>{
                                                delModel(item)
                                            }} className='del_icon'>
                                                <img src={DelIcon} alt=''/>
                                            </div>
                                        </div>
                                        <div className='ai-name' title={item.name}>{item.name}</div>
                                    </div>
                                )
                            })
                        }
                        <div className='ai-box'>
                            <div className='ai-mod' onClick={()=>{SelectModelRef.current.setVisible(true)}} >
                                <IconPlus style={{color:'#02C1A3',fontSize:'14px'}}/>
                            </div>
                            <div className='ai-name'></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 选择模型弹窗 */}
            <SelectModel ref={SelectModelRef} onChildEvent={(data) => {updateModel(data.together_ids)}}
/>
        </>
    );
}

export default Home;
