import service from "../utils/server/server";// 这个模块封装各种请求方式（如：get，post等等）
import { Message } from "@arco-design/web-react";
export function post(url, params) {
  return new Promise(function (resovle, reject) { 
    service.post(url, params)
    .then(res => { 
      if (!res?.data) { resovle(res); } resovle(res?.data); 
    }, err => { reject(err); })
    .catch(err => { reject(err); }); 
  });
}
export function get(url, params) {
  let querystr = "";
  for (let key in params) { if (params[key]) { querystr += `${key}=${params[key]}&` } }
  return new Promise(function (resovle, reject) { service.get(url + "?" + querystr).then(res => { if (!res.data) { resovle(res); } resovle(res.data); }, err => { reject(err); }).catch(err => { reject(err); }); });
}