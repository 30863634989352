import * as axiosBase from './index.js'; 
const userInfo = function (params) {
  params = params || {}
  return axiosBase.get('/userinfo', params)
}

const sendCode = function (params) {
  params = params || {}
  return axiosBase.post('/send_msg', params)
}

const login = function (params) {
  params = params || {}
  return axiosBase.post('/login', params)
}

const coupon = function (params) {
  params = params || {}
  return axiosBase.get('/coupon', params)
}

const getOrder = function (params) {
  params = params || {}
  return axiosBase.get('/get_order', params)
}

const getSetting = function (params) {
  params = params || {}
  return axiosBase.get('/get_setting', params)
}

const saveSetting = function (params) {
  params = params || {}
  return axiosBase.post('/save_setting', params)
}

const getRobot = function (params) {
  params = params || {}
  return axiosBase.get('/get_robot_list', params)
}

const orderList = function (params) {
  params = params || {}
  return axiosBase.get('/get_order_list', params)
}

const vipList = function (params) {
  params = params || {}
  return axiosBase.get('/vip_list', params)
}

const chat = function (params) {
  params = params || {}
  return axiosBase.post('/chat', params)
}

const pay = function (params) {
  params = params || {}
  return axiosBase.get('/get_order', params)
}

const payState = function (params) {
  params = params || {}
  return axiosBase.get('/get_order_status', params)
}

const getWxQrcode = function (params) {
  params = params || {}
  return axiosBase.get('/get_wx_qrcode', params)
}

const getWxLogin = function (params) {
  params = params || {}
  return axiosBase.get('/wx_login', params)
}

const updateUserInfo = function (params) {
  params = params || {}
  return axiosBase.post('/update_userinfo', params)
}

const getChats = function (params) {
  params = params || {}
  return axiosBase.get('/get_chats', params)
}

const deleteChat = function (params) {
  params = params || {}
  return axiosBase.post('/delete_chat', params)
}

const uploadImg = function (params) {
  params = params || {}
  return axiosBase.post('/upload_img', params)
}

const uploadPhone = function (params) {
  params = params || {}
  return axiosBase.post('/update_phone', params)
}

const delWx = function (params) {
  params = params || {}
  return axiosBase.post('/del_wx', params)
}

const createChat = function (params) {
  params = params || {}
  return axiosBase.post('/create_chat', params)
}

const updateChat = function (params) {
  params = params || {}
  return axiosBase.post('/update_chat', params)
}


const getSessions = function (params) {
  params = params || {}
  return axiosBase.get('/get_sessions', params)
}


const getSessionsDetail = function (params) {
  params = params || {}
  return axiosBase.get('/get_session', params)
}


const postLike = function (params) {
  params = params || {}
  return axiosBase.post('/like', params)
}

const postDisLike = function (params) {
  params = params || {}
  return axiosBase.post('/dislike', params)
}


// 修改用户信息
// /chatalls/update_userinfo    post
// { name, head, job, company }

// 获取聊天记录
// /chatalls/get_chats    get
// robot_id  user_id page_num page_size

// 删除聊天记录
// /chatalls/delete_chat    post
// { id }

export {
  userInfo,
  sendCode,
  login,
  coupon,
  getOrder,
  getSetting,
  saveSetting,
  getRobot,
  orderList,
  vipList,
  chat,
  pay,
  payState,
  getWxQrcode,
  getWxLogin,
  updateUserInfo,
  getChats,
  deleteChat,
  uploadImg,
  uploadPhone,
  delWx,
  createChat,
  updateChat,
  getSessions,
  getSessionsDetail,
  postLike,
  postDisLike
}