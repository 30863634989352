
import { useState,useEffect } from 'react'
import {
  useRecoilValue,
  useRecoilState
} from 'recoil';
import { robotListState,settingInfo,settingInfoState } from './store'

import '../../pages/App.css';
import { Select, Message, Button, Checkbox  } from '@arco-design/web-react';
import {saveSetting as saveSettingApi,userInfo as getUser} from '../../api/all'

const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;

const modeOption = [{
  id:2,
  name:'二合一'
},{
  id:3,
  name:'三合一'
},{
  id:4,
  name:'四合一'
},{
  id:6,
  name:'六合一'
}]
const numMap = {
  2:'二',3:'三',4:'四',6:'六',8:'八'
}

function Setting() {

  const robotList = useRecoilValue(robotListState);
  const setting = useRecoilValue(settingInfoState);
  const [config, setConfig] = useRecoilState(settingInfo)

  
  const [indexId,setIndexId] = useState(1)
  const [together,setTogether] = useState(2)
  const [togetherIds, setTogetherIds] = useState([]); 
  const [userInfo, setUserInfo] = useState(null)

useEffect(()=>{
  getUserInfo()
},[])

useEffect(()=>{
  setIndexId(setting?.index_robot_id)
  setTogether(setting?.together)
  setTogetherIds(setting?.together_ids?.split(",").map((a)=>Number(a)))
},[setting])

const getUserInfo = ()=>{
  getUser().then((res)=>{
    let user = res.result
    setUserInfo(user)
  })
}
  const onChange = (checkList) => {
    // if(checkList.length > together){
    //   return Message.warning(`所选聚合模式为${numMap[together]||together}合一,聚合机器人不能超过${together}个`)
    // }
    setTogetherIds(checkList);
  }

  const saveSetting = () => {
    // if(Number(togetherIds.length) !== Number(together)){
    //   return Message.warning(`所选聚合模式和聚合机器人个数不相等！`)
    // }
    sumitData({user_id: userInfo.id, index_robot_id: indexId, together, together_ids: togetherIds.join(","), id: setting.id})
  }

  const sumitData = (params) => {
    saveSettingApi(params).then(res => {
      if(res.code === 0){
        setConfig(params)
        Message.success(`保存成功`)
        sessionStorage.setItem("recentsetting", 1);
      }
    },error => {
        console.log("get request failed:",error);
    });
  }

  return (
    <div className="px-[15px] py-3 h-full overflow-hidden">
    <div className="flex flex-col overflow-hidden bg-primary-background dark:text-primary-text rounded-[10px] h-full">
      <div className="text-center border-b border-solid border-primary-border flex flex-col justify-center mx-10 py-3"><span
          className="font-semibold text-lg">设置</span></div>
      <div className="px-10 h-full overflow-auto">
        <div className="flex flex-col gap-5 mt-3">
          <div>
            <p className="font-bold mb-2 text-lg">启动页面</p>
            <div className="w-[200px]">
            <Select
              value={indexId}
              placeholder='请选择启动页面'
              style={{ width: 154 }}
              onChange={(value,item) =>{
                Message.info({
                  content: `你选择了${item.label}`,
                  showIcon: true,
                })
                setIndexId(value)
              }}
            >
              {robotList.map((option) => (
                <Option key={option.id} label={option.name} value={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>
            </div>
          </div>
          <div>
            <p className="font-bold mb-2 text-lg">聚合模式</p>
            <div className="w-[200px]">
              <Select
                value={together}
                placeholder='请选择聚合模式'
                style={{ width: 154 }}
                onChange={(value,item) =>{
                  Message.info({
                    content: `你选择了${item.label}`,
                    showIcon: true,
                  })
                  setTogether(value)
                  if(togetherIds.length > value){
                    setTogetherIds(togetherIds.splice(0,value))
                  }
                }}
              >
                {modeOption.map((option) => (
                  <Option key={option.id} label={option.name} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div>
            <p className="font-bold mb-2 text-lg">聚合机器人</p>
            <div className="">
              <CheckboxGroup
                value={togetherIds}
                options={robotList.map((x, i) => ({
                  label: x.name,
                  value: x.id
                }))}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div style={{height: "20px", pointerEvents: "none"}}></div>
        <Button shape='round' onClick={()=>{
          saveSetting()
        }} type='primary'>
          保存
        </Button>
        <div style={{position: "fixed", zIndex: 9999, inset: "16px", pointerEvents: "none"}}></div>
      </div>
    </div>
  </div>
  );
}

export default Setting;
