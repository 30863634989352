import {
  atom,
  selector,
} from 'recoil';

// 單個對話 当前AI
const currentAiChat = atom({
  key: 'currentAiChat', 
  default: {"name": "文心一言", "logo": "http://cdn.aiconsultation.cn/chatalls/robot/文心一言.png", "reply_type": 1, "id": 1},
});

const currentAiChatState = selector({
  key: 'currentAiChatState',
  get: ({get}) => {
    const text = get(currentAiChat);
    return text;
  },
});


// 全部AI对话列表
const chatList = atom({
  key: 'chatList', 
  default: {1:{},2:{}},
});

const chatListState = selector({
  key: 'chatListState',
  get: ({get}) => {
    const text = get(chatList);
    return text;
  },
});

// 全部AI列表
const robotList = atom({
  key: 'robotList', 
  default:[],
});

const robotListState = selector({
  key: 'robotListState',
  get: ({get}) => {
    const text = get(robotList);
    return text;
  },
});


// 设置信息
const settingInfo = atom({
  key: 'settingInfo', 
  default: [{
    "index_robot_id": 1,
    "together": 6,
    "together_ids": "1,3,4,5,9,11"
  }],
});

const settingInfoState = selector({
  key: 'settingInfoState',
  get: ({get}) => {
    const text = get(settingInfo);
    return text;
  },
});

// 折叠状态
const allCollapseStatus = atom({
  key: 'allCollapseStatus',
  default: true,
});


// 用户信息
const userInfo = atom({
  key: 'userInfo',
  default: {},
});

const userInfoState = selector({
  key: 'userInfoState',
  get: ({get}) => {
    const text = get(userInfo);
    return text;
  },
});


// 全部聊天记录
const chatAllList = atom({
  key: 'chatAllList',
  default: [],
});

const chatAllListState = selector({
  key: 'chatAllListState',
  get: ({get}) => {
    const text = get(chatAllList);
    return text;
  },
});


// 模型logoMap
const logoMap = atom({
  key: 'logoMap',
  default: {},
});

const logoMapState = selector({
  key: 'logoMapState',
  get: ({get}) => {
    const text = get(logoMap);
    return text;
  },
});


export {
  currentAiChat,
  currentAiChatState,
  chatList,
  chatListState,
  robotList,
  robotListState,
  settingInfo,
  settingInfoState,
  allCollapseStatus,
  userInfo,
  userInfoState,
  chatAllList,
  chatAllListState,
  logoMap,
  logoMapState
}