import { Result, Button } from '@arco-design/web-react';
import './Success.scss'
import { useLocation,useNavigate } from 'react-router-dom';
const Status = () => {
  const navigate = useNavigate();
  return (
    <div className='success-page'>
      <Result
        status='success'
        title='支付成功'
        subTitle='可以愉快的玩耍了'
        extra={[
          <Button onClick={()=>{
            window.history.back()
          }} key='back' type='secondary' style={{ margin: '0 16px' }}>
            返回
          </Button>,
          <Button onClick={()=>{
            navigate('/premium')
          }} key='center' type='primary'>
            个人中心
          </Button>,
        ]}
      ></Result>
    </div>
  );
};

export default Status;
