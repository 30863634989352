import '../../App.css';
import './Index.scss'
import NodataIcon from '../../../assets/v2/imgs/nodata.png';
import Thinking from '../../../assets/v2/imgs/thinking.png'
import { useNavigate  } from "react-router-dom"
import { useState, useEffect, useRef, useCallback } from 'react'
import { getRobot } from '../../../api/all'
import axios from "axios";



axios.defaults.headers.common['token'] = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');;
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';

function Model() {
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [models, setModels] = useState([])
    const [filterModels,setFilterModels] = useState(models)

    const getModels = useCallback(()=>{
        getRobot({page_size:100,
            page_num:1}).then(res => {
            if(res.code === 0){
              setModels(res.result.list)
            }else{
              setModels([])
            }
          });
    },[setModels])

    useEffect(() => {
        setFilterModels(models)
    }, [models])

    useEffect(() => {
        getModels()
    }, [getModels])

    const searchModel = useCallback(()=>{
        let searchTxt = inputRef.current.value
        let list = models.filter((item)=>{
            return item.name.indexOf(searchTxt)>-1
        })
        setFilterModels(list)
    },[models])

    const handleEnterPress = useCallback((event)=>{
        if (event.key === 'Enter') {
            searchModel()
        }
    },[searchModel])

    const clickAi = (item)=>{
        navigate("/home?rid="+item.id)
    }

    return (
        <>
            <div className="main modelbg">
                <div className="cont">
                    <div className="title">一键连接<span className="dian"></span><span className="num">78+</span>个AI能力</div>
                    <div className="subtitle">一站式AI体验，这里是你的AI智囊团</div>
                    <div className="search_box">
                         <input
                            ref={inputRef}
                            className="search_input"
                            placeholder='请输入关键词'
                            style={{ width: 620, margin: 10 }}
                            // searchButton={true}
                            onKeyDown={handleEnterPress}
                        />
                        <div className="search_box_img">
                            <img className="icon_search" src={require('../../../assets/v2/imgs/searchwhite.png')} onClick={()=>{searchModel()}} alt="搜索" />
                        </div>
                    </div>
                    <div className="tools-box">
                        <div className="ai-group"><img src={require('../../../assets/v2/imgs/aichat.png')} alt=""/>AI对话</div>
                    </div>


                    {
                            filterModels.length === 0?<>
                                <div className='nodata'>
                                    <img src={NodataIcon} alt=''/>
                                    <p>暂无数据</p>
                                </div>
                            </>:<>
                            <div className="models">
                        {
                            filterModels.map((item)=>{
                                return (
                                    <div key={item.id} onClick={()=>{
                                        clickAi(item)
                                    }} className="model">
                                        {
                                            item.type === 1?<div className="mod-tag">会员专享</div>:''
                                        }
                                        <div className="mod-logo">
                                            <img src={item.logo?'https://chatalls.com/'+item.logo:Thinking} alt=""/>
                                        </div>
                                        <div className="mod-info">
                                            <div className="mod-name">
                                                {item.name}
                                            </div>
                                            <div className="mod-introduce" title={item.desc}>
                                                {item.desc || '我是'+item.name+'大模型  欢迎使用'}
                                            </div>
                                            <div className="mod-type">
                                                AI对话
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div></>
                        }



                </div>
            </div>
        </>
    );
}

export default Model;
