

import React, { useState,useRef,useEffect,forwardRef,useImperativeHandle,useCallback } from 'react';
import { NavLink } from "react-router-dom";
import { Modal, Upload, Button, Checkbox,Spin } from '@arco-design/web-react';
import { IconPlusCircle,IconMinusCircle,IconSync } from '@arco-design/web-react/icon';
import AllChatIcon from '../../../assets/v2/imgs/aichat.png'
import zhanweiIcon from '../../../assets/v2/imgs/zhanweitu.png'

import './Index.scss'
import {
    useRecoilValue,
    useRecoilState
  } from 'recoil';
import { logoMap } from '../store'
import {userInfo as getUser,getRobot,getSetting,getSessions} from '../../../api/all'
const AllChat = forwardRef((props, ref) => {
    const [chatList, setChatList] = useState([])
    const robotLogoMap = useRecoilValue(logoMap)
    useImperativeHandle(ref, (props) => ({
        // 往外暴露的方法
    }));

    useEffect(() => {
        getAllHistory()
    }, []);
  
    const getAllHistory = () => {
        getSessions({
            page_num:1,
            page_size:20
        }).then((res)=>{
        setChatList(res.result.list)
        })
    }
    const changeUrl = (item)=>{
        return item.robot_id.indexOf(',')>-1 ?  ("/all?cid="+item.id):("/home?cid="+item.id)
    }

  return (
    <div className='allchat'>
        <div className='big-title'>
            <img src={AllChatIcon} alt=''/><p>所有聊天</p>
        </div>
        <div className='chats'>
            {
                chatList.map((item)=>{
                    return (
                        <NavLink to={changeUrl(item)} key={item} className='chat-item'>
                            <div className='ch-logo'>
                                <img src={robotLogoMap[item.robot_id] ? 'https://chatalls.com/'+robotLogoMap[item.robot_id]: zhanweiIcon} alt=''/>
                            </div>
                            <div className='ch-info'>
                                <div className='ch-name'>{JSON.parse(item.chat)[0]?.robot.name || item.name}</div>
                                <div className='ch-name'>{ JSON.parse(item.chat)[0]?.user || '暂无问题'}</div>
                                <div className='ch-answer'>
                                    { JSON.parse(item.chat)[0]?.result || '暂无回答'}
                                </div>
                                <div className='ch-date'>{item.updated_at.slice(0,10)}</div>
                            </div>
                        </NavLink>
                    )
                })
            }
        </div>
    </div>
  )
})

export default AllChat