import { useState,useEffect,useCallback ,useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal,Message,Spin,Input } from "@arco-design/web-react";
import "@arco-design/web-react/dist/css/arco.css";
import './Buy.scss';
import '../../pages/App.css';
import QRCode  from 'qrcode.react';
import {vipList as getVipList,getOrder,coupon as postCoupon,payState} from '../../api/all'

import DuihuanIcon from '../../assets/duihuan.svg'
import Wxpay from '../../assets/weixinpay.png'
import SuccessIcon from '../../assets/success.svg'
import ErrorIcon from '../../assets/error.svg'

import useIntervalAsync from '../hook/useIntervalAsync'

function Buy() {
  const navigate = useNavigate();
  const [vipList, setVipList] = useState([])
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [selectVipType,setSelectVipType] = useState(6)

  const [visibleExchange, setVisibleExchange] = useState(false);
  const [visibleTips, setVisibleTips] = useState(false);
  const [tipsType, setTipsType] = useState(1);
  const inputRef = useRef(null)

  useEffect(() => {
    getUserInfo()
  }, [])

  const updateState = useCallback(async () => {
    if(orderInfo && visible){
      const response = await payState({
        order_id:orderInfo?.order_id
      });
      if(response.code == 0){
        if(response.result[0].status == 2){
          update(0)
          setVisible(false)
          setOrderInfo(null)
          navigate('/success')
        }
      }
    }
  }, [orderInfo]);

  const update  =  useIntervalAsync(updateState, 3000);

  const getUserInfo = ()=>{
    getVipList().then(res => {
      let vip_list = res.result
      setVipList(vip_list)
      toBuy(vip_list[0])
    },error => {
      console.log("get request failed:",error);
    });
  }

  const toBuy = ({id,coupon=''})=>{
    // setVisible(true)
    setLoading(true)
    console.log(id)
    getOrder({id,coupon}).then(res => {
      let data = res.result
      setOrderInfo(data)
      setLoading(false)
      update(1)
    },error => {
      setLoading(false)
      console.log("get request failed:",error);
    });
  }

  const onExchange = (code,index)=>{
    console.log(code,index)
    postCoupon({code}).then((res)=>{
      if(res.code === 0){
        let a = [...vipList]
        a[index].price = (a[index].price - res.result.price) > 0 ? (a[index].price - res.result.price) : 0.01
        a[index].coupon = code
        setVipList(a)
        setTipsType(1)
        setVisibleTips(true)
      }else{
        setTipsType(0)
        setVisibleTips(true)
      }
    })
  }

  return (
    <div className="px-[15px] py-3 h-full overflow-hidden">
      <div className="flex flex-col bg-primary-background dark:text-primary-text rounded-[10px] h-full overflow-y-auto" style={{paddingBottom:'0.67em'}}>
      <div className='top_box'>
          <div className="tit">会员中心</div>
          <div onClick={()=>{
            setVisibleExchange(true)
          }} className="flex flex-row items-center gap-1 min-w-max"><img src={DuihuanIcon} alt=''/><span>兑换优惠券</span></div>
      </div>
      <div className='buy_content'>
      <div className='buy_free_vip'>
        <div className='imgbg'>
          <div className='vip_tit'>免费会员</div>
          <div className='vip_tips'>每位用户可享受每月20次免费问答</div>
        </div>
      </div>

      <ul className='ul'>
          {
            vipList.map((item,index)=>{
              return (
                <li className={`item ${selectVipType===item.id?'item_select':''}`} key={item.id} onClick={()=>{
                  setSelectVipType(item.id)
                  toBuy(item)
                }}>
              <div className="name ellipsis">{item.name}</div>
              <div className='ask_num'>可享受每{item.id === 7 ?'年':'月'}{item.count}次问答</div>
              <div className='price'>
                <span className="text-[40px] leading-none font-bold price_num">{item.price}</span>
                <span className="leading-none font-bold">元/{item.id === 7 ?'年':'月'}</span>
              </div>
              {/* <div className='flex-bet'>
                <span>支付方式</span>
                <div className='flex-center'>
                <Image
                  width={20}
                  preview={false}
                  src={WexinIcon}
                  alt='weixin'
                />  
                  <span style={{marginLeft:'5px'}}>微信</span>
                </div>
              </div> */}
              {/* <Button size='large' style={{marginTop:'10px'}} onClick={()=>{toBuy(item)}} shape='round' type='primary'>
                立即购买
              </Button> */}
          </li>
              )
            })
          }
      </ul>

      <div className='pay_box'>
          <div className='pay_qrcode_box'>
            <div className='pay_qrcode'>
              {/* <img src='' alt=''/> */}
              {
                (loading || !orderInfo)?<Spin tip='支付码加载中....' loading={loading}></Spin>:<><></>
                  <div style={{padding:'10px 0'}}>
                  <QRCode
                      value={orderInfo.url.code_url} //value参数为字符串类型
                      size={120} //二维码的宽高尺寸
                      fgColor="#000000"  //二维码的颜色
                  />
                  </div>
                </>
              }
            </div>
            <div className='wxpay'>
              <img src={Wxpay} alt=''/>
              <div>微信支付</div>
            </div>
          </div>
          <div className='pay_price_tips'>
            <div className=''>实付金额：
              {orderInfo?<span className="text-[40px] leading-none font-bold price_num">¥{orderInfo.price}</span>:''}
            </div>
            <div className='tips'>
              请使用微信扫码支付
            </div>
          </div>
      </div>
      </div>

      {/* <div style={{position: "fixed", zIndex: 9999, inset: "16px", pointerEvents: "none"}}></div> */}
      </div>

      <Modal
        className="xzy_modal"
        style={{
          width: '260px',
          height: '176px',
          border: '1px solid #D6D6D6',
          borderRadius:'20px'
        }}
        title='兑换优惠券'
        visible={visibleExchange}
        autoFocus={true}
        onCancel={() => setVisibleExchange(false)}
        footer={<div className='btns'>
          <div className='butn cancel' onClick={() => setVisibleExchange(false)}>取消</div>
          <div className='butn confirm' onClick={() => {
            setVisibleExchange(false)
            onExchange(inputRef.current.dom.value,selectVipType-6)
            // inputRef.current.dom.value = ''
          }}>兑换</div>
        </div>}
      >
        <Input ref={inputRef} allowClear  placeholder='请输入兑换码' />
      </Modal>

      <Modal
        className="xzy_modal"
        style={{
          width: '260px',
          height: '176px',
          border: '1px solid #D6D6D6',
          borderRadius:'20px'
        }}
        title='兑换优惠券'
        visible={visibleTips}
        autoFocus={true}
        onCancel={() => setVisibleTips(false)}
        footer={<div className='btns'>
          <div className='butn confirm' style={{width:'226px'}} onClick={() => {
            setVisibleTips(false)
          }}>确定</div>
        </div>}
      >
        <div className='tipscontent'>
          <img src={tipsType ? SuccessIcon:ErrorIcon} alt=''/>{tipsType?'兑换成功':'未查询到优惠券'}
        </div>
      </Modal>
    </div>
  );
}

export default Buy;
