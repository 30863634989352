

import React, { useState,useRef,useEffect,forwardRef,useImperativeHandle,useCallback } from 'react';
import { Modal, Input, Button, Checkbox,Message } from '@arco-design/web-react';
import SearchIcon from '../../../assets/v2/imgs/search_sel.png'
import './SelectModel.scss'
import {userInfo as getUser} from '../../../api/all'
import {
    useRecoilValue,
    useRecoilState
  } from 'recoil';
  import { robotListState,settingInfoState,userInfo as userInfoData } from '../store'

function setZoomByClassName(className,value) {
    var elements = document.getElementsByClassName(className);
    // 遍历每个元素并设置其CSS样式
    for (var i = 0; i < elements.length; i++) {
        // 设置CSS样式
        elements[i].style.zoom = value;
    }
}
const CheckboxGroup = Checkbox.Group;

const SelectModel = forwardRef(({onChildEvent}, ref) => {
    const robotList = useRecoilValue(robotListState);
    const [filterModels,setFilterModels] = useState(robotList)
    const setting = useRecoilValue(settingInfoState);
    const [indexId,setIndexId] = useState(1)
    const [together,setTogether] = useState(2)
    const [userInfo, setUserInfo] = useRecoilState(userInfoData)
    const [searchTxt,setSearchTxt] = useState('')

    

    const [visible,setVisible] = useState(false)
    const [togetherIds, setTogetherIds] = useState([]); 
    const [checkList,setChecklist] = useState([])
          
    useImperativeHandle(ref, (props) => ({
        setVisible,setTogetherIds
    }));

    useEffect(()=>{
        setIndexId(setting[0]?.index_robot_id)
        setTogether(setting[0]?.together)
        setTogetherIds(setting[0]?.together_ids?.split(",").map((a)=>Number(a)))
    },[setting])

    const onChange = (checkList) => {
        // if(checkList.length > together){
        //   return Message.warning(`所选聚合模式为${numMap[together]||together}合一,聚合机器人不能超过${together}个`)
        // }
        setTogetherIds(checkList);
    }
    
    useEffect(()=>{
        // console.log(togetherIds)
        let checklist = robotList.filter((item)=>{
            return  togetherIds.indexOf(item.id)>-1   
        })
        setChecklist(checklist)
    },[robotList, togetherIds])


    const saveSetting = () => {
        if(userInfo){
            sumitData({user_id: userInfo.id, index_robot_id: indexId, together, together_ids: togetherIds.join(","), id: setting[0].id})
        }else{
            getUser().then((res)=>{
                let user = res.result
                setUserInfo(user)
                sumitData({user_id: user.id, index_robot_id: indexId, together, together_ids: togetherIds.join(","), id: setting[0].id})
            })
        }
    }
    
    const sumitData = (params) => {
        onChildEvent(params)
    }

    useEffect(() => {
        var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if(windowHeight<900){
            setZoomByClassName('zoom',windowHeight/900)
            setZoomByClassName('newfixed',900/windowHeight)
        }
        return () => {

        };
    }, []);
    return (
        <>
        <Modal
        mask={false}
        className="selectmodel"
        title={null}
        visible={visible}
        style={{
            width:'494px',
            height: '458px',
            background: '#FFFFFF',
            boxShadow: '0px 0px 11px 0px rgba(191,191,191,0.37)',
            borderRadius: '20px'
        }}
        autoFocus={true}
        onCancel={() => setVisible(false)}
        footer={null}
        >
            <div className="select_model">
                <div className="model_tit">
                    <div className="model_tit_txt">模型选择</div>
                </div>
                <div className="model_list_box">
                    <div className="model_list_cont">
                        <div style={{paddingLeft:'12px'}}>
                            <div className="search_model">
                                <Input onChange={(val)=>{setSearchTxt(val)}} allowClear placeholder='搜索'/>
                                <img className='search_icon' src={SearchIcon} alt=''/>
                            </div>
                            <div className="all_count">
                                共{robotList.length || 0}项
                            </div>
                        </div>
                        <div key={'lll'} className="model_list">
                            <Checkbox.Group direction='vertical' value={togetherIds} onChange={onChange}>
                                {robotList.map((option) => {
                                if (option.name.indexOf(searchTxt)>-1) {
                                    return (
                                        <Checkbox key={option.id} value={option.id}>
                                            {option.name}
                                        </Checkbox>
                                    );
                                }else{
                                    return(
                                        <Checkbox style={{display:'none'}} key={option.id} value={option.id}>
                                            {option.name}
                                        </Checkbox>
                                    )
                                }
                                })}
                            </Checkbox.Group>

                        </div>
                    </div>
                    <div className="model_check">
                        <p className="count">已选<span>{ checkList?.length }</span>项<span onClick={()=>{
                             setTogetherIds([]);
                        }} className="clear_all">清空</span></p> 
                        <div class="model_check_list">
                            {
                                checkList?.length ?  checkList.map((item)=>{
                                    return (
                                        <div className="model_check_txt">{item.name}</div>
                                    )
                                }):''
                            }
                        </div>
                    </div>
                </div>
                <div className="btn_box_sel">
                    <div className="cancel_btn" onClick={()=>{setVisible(false)}}>取消</div>
                    <div className="sure_btn" onClick={()=>{saveSetting()}}>确认</div>
                </div>
            </div>
        </Modal>
        {/* <div className="select_btn" onClick={()=>{setVisible(true)}}>开启</div> */}
        </>
    )
})

export default SelectModel