import { useState, useRef,useEffect,useLayoutEffect  } from 'react'

import '../../pages/App.css';
import './Premium.scss';
import { Descriptions,Table,Modal,Input,Message,Avatar,Spin,Checkbox } from "@arco-design/web-react";
import { IconExport,IconEdit,IconDelete } from '@arco-design/web-react/icon';
// import Draggable from 'react-draggable';
import "@arco-design/web-react/dist/css/arco.css";
import { useNavigate } from "react-router-dom";
import LoginDialog from "../../pages/main/LoginDialog";
import DelConfirm from './Dialog/DelConfirm';


import {userInfo as getUser,sendCode,orderList,updateUserInfo,getWxQrcode,uploadPhone,delWx} from '../../api/all'
import VipfreeIcon from '../../assets/user_vip_free.svg'
import VipmonthIcon from '../../assets/user_vip_month.png'
import VipyearIcon from '../../assets/user_vip_year.svg'
import EditIcon from '../../assets/v2/imgs/edit.png'
import TotalCountIcon from '../../assets/total_count.svg'
import DiffCountIcon from '../../assets/diff_count.svg'
import AvatarIcon from '../../assets/v2/imgs/avatar.png'
import WxpayIcon from '../../assets/wx.png'
import settingIcon from '../../assets/v2/imgs/setting.png'
import UploadHead from './Dialog/UpHead'
import VipDialog from "./Dialog/Vip";


import {
  useRecoilValue,
  useRecoilState
} from 'recoil';
import { userInfo as userInfoState } from './store'
const columns = [
  {
    title: '充值编号',
    dataIndex: 'id'
  },
  {
    title: '充值总额',
    dataIndex: 'price'
  },
  {
    title: '充值类型',
    dataIndex: 'type'
  },
  {
    title: '日期',
    dataIndex: 'order_time'
  }
];
function Premium() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState)
  const [userInfoCol, setUserInfoCol] = useState([])
  const [dataSource,setDataSource] = useState([])
  const [visible, setVisible] = useState(false);
  const [visibleWx, setVisibleWx] = useState(false);
  const [qrcodeScene,setQrcodeScene] = useState(null)
  const [loading, setLoading] = useState(false);
  const [visiblePhone,setVisiblePhone] = useState(false)
  const [phoneNumber,setPhoneNumber] = useState('')
  const [msgCode,setMsgCode] = useState('')  
  const [searchButton,setSearchButton] = useState(0)
  const countDownTimer = useRef(null)
  const loginRef = useRef(null)
  const inputRef = useRef(null)
  const UploadHeadRef = useRef(null)
  const DelConfirmRef = useRef(null)
  const VipDialogRef = useRef(null)
  const [userInfoHead,setUserInfoHead] = useState(userInfo?.head)

  useLayoutEffect(() => {
    if(document.getElementsByClassName('arco-pagination-item-prev')[0]){
      document.getElementsByClassName('arco-pagination-item-prev')[0].innerHTML = "上一页";
      document.getElementsByClassName('arco-pagination-item-next')[0].innerHTML = "下一页";
    }
  }, []); // 空数组保证仅在组件挂载时执行一次

  useEffect(() => {
    return () => {
      clearTimeout(countDownTimer.current);
    };
  }, []);

  useEffect(()=>{
      if(searchButton===0){
          clearInterval(countDownTimer.current)
      }
  },[searchButton])

  const getMsgCode = ()=> {
    if (phoneNumber === "") {
      Message.error("请输入手机号码");
      return;
    }
    if (phoneNumber.length !==11) {
      Message.error("请输入正确的手机号码");
      return;
    }
    let params = {
      phone: phoneNumber,
      type: "login"
    };
    sendCode(params).then(res => {
      if (res?.code === 0) {
        countDown()
      }
    },error => {
        console.log("get request failed:",error);
    });
  }
  const countDown = (a,b)=>{
    setSearchButton(60)
    countDownTimer.current=setInterval(()=>{
      setSearchButton(c=>c-1)
    },1000)
  }
  useEffect(() => {
    getUserInfo()
    setLoading(true)
    getWxQrcode().then((res)=>{
      setQrcodeScene(res.result)
      setLoading(false)
    })
  }, [])

  const getUserInfo = ()=>{
    getOrderList()
  }

  const getuserAjax = ()=>{
    getUser().then((res)=>{
      let user = res.result
      let datauser = [
        {
          label: '用户ID',
          value: user.id,
        },
        {
          label: '手机号码',
          value: user.phone,
        },
        {
          label: '注册时间',
          value: user.created_at.replace('T',' '),
        },
      ];
      setUserInfoCol(datauser)
      setUserInfo(user)
      setUserInfoHead(user.head)
    })
  }
  const getOrderList = ()=>{
    orderList().then((res)=>{
      res && res.result?.map((obj)=>{
        obj['price'] = obj.vip_info.price
        obj['type'] = obj.vip_info.name
        obj.order_time = obj.order_time.replace('T',' ')
      })
      setDataSource(res.result)
    })
  }

  const updateUserAjax = (params)=>{
    updateUserInfo({...userInfo,...params}).then((res)=>{
      if(res?.code===0){
        Message.success('修改成功')
        getuserAjax()
      }else{
        Message.error(res.msg)
      }
    })
  }
  const updataUserHeadImg = (img)=>{
    UploadHeadRef.current.setVisible(false)
    updateUserAjax({head:img})
  }

  const updatePhoneAjax = (params)=>{
    uploadPhone({...params}).then((res)=>{
      if(res?.code===0){
        Message.success('修改成功')
        getuserAjax()
      }else{
        Message.error(res.msg)
      }
    })
  }

  return (
    <>
    <div className='user-center'>
      <div className='big-title'>
        <img src={settingIcon} alt=''/><p>设置</p>
      </div>
      <div className='uc-tit first-tit'>
          基本信息
      </div>
      <div className='basic'>
          <div className='img'>
            {/* <img src={AvatarIcon} alt='avatar'/> */}
            <Avatar triggerIcon={<div><IconEdit onClick={()=>{UploadHeadRef.current.setVisible(true);UploadHeadRef.current.setHeaderImg(userInfo.head)}}/><IconDelete style={{display:'none'}} onClick={()=>{DelConfirmRef.current.setVisible(true)}}/></div>} triggerType='mask'>
              <img
                alt='avatar'
                src={userInfoHead || userInfo.head}
                onError={()=>{
                  setUserInfoHead(AvatarIcon)
                }}
              />
            </Avatar>
          </div>
          <div className='bas-info'>
            <div className='user-name'>{userInfo.name}<img src={EditIcon} alt='' className='edit' onClick={()=>{setVisible(true)}} style={{fontSize:'12px'}}/></div>
            <div className='user-phone'>手机号：{userInfo.phone}<img src={EditIcon} alt='' className='edit' onClick={()=>{setVisiblePhone(true)}} style={{fontSize:'12px'}}/></div>
            <div className='user-wx'>微信：{userInfo?.openid?'已绑定':'未绑定'}
              {
                userInfo?.openid ? <span className='unbind' onClick={()=>{delWx().then((res)=>{
                  console.log(res)
                })}}>解绑</span>:<></>
              }
            </div>
          </div>
      </div>
      <div className='uc-tit'>
          会员信息
      </div>
      <div className='vip-info'>
          <div className='v-info vip-level'>
            <span className='c-label'>会员等级：</span><span className='c-value'>{userInfo?.is_vip===0?'免费':userInfo?.vip_id===6?'月':'年'}会员</span><span onClick={()=>{VipDialogRef.current.setVisible(true)}} className='c-upbtn'>升级</span>
          </div>
          <div className='v-info'>
            <span className='c-label'>会员到期时间：</span><span className='c-value'>{userInfo?.end_time || '暂无数据'}</span>
          </div>
          <div className='v-info'>
            <span className='c-label'>加油包个数：</span><span className='c-value'>{Number(userInfo?.count - userInfo?.use_count)+''}</span>
          </div>
          <div className='v-info'>
            <span className='c-label'>加油包到期时间：</span><span className='c-value'>当月有效</span>
          </div>
      </div>
      <div className='uc-tit'>
          支付记录
      </div>
      <Table className="custom-table" columns={columns} rowKey={'id'} border={false} stripe data={dataSource} style={{
                border:'none'
              }}/>
    </div>

    <LoginDialog ref={loginRef}/>
    <UploadHead ref={UploadHeadRef} onChildEvent={(img)=>{updataUserHeadImg(img)}}/>
    <DelConfirm ref={DelConfirmRef}/>

    <Modal
      className="pre_xzy_modal"
      title={null}
      visible={visible}
      style={{
        width:'334px',
        height: '293px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 11px 0px rgba(191,191,191,0.37)',
        borderRadius: '20px'
      }}
      autoFocus={true}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className='titss'>
        更改用户姓名
      </div>
      <div className='name-input'>
       <span className='ni-label'>用户名</span><Input ref={inputRef} allowClear  placeholder='请输入新用户名' />
      </div>
      <div className='surebtnn' style={{marginTop:"53px"}} onClick={()=>{
        setVisible(false)
        updateUserAjax({name:inputRef.current.dom.value})
      }}>
        确认修改
      </div>
    </Modal>

    <Modal
      className="pre_xzy_modal"
      title={null}
      visible={visiblePhone}
      style={{
        width:'334px',
        height: '323px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 11px 0px rgba(191,191,191,0.37)',
        borderRadius: '20px'
      }}
      autoFocus={true}
      onCancel={() => setVisiblePhone(false)}
      footer={null}
    >
      <div className='titss'>
        更改注册手机号
      </div>

      <div className='input-box'>
          {/* <Input prefix={<span>手机号</span>}  size='large' allowClear maxLength={11} height={46} placeholder='请输入手机号' value={phoneNumber} onChange={(value)=>{setPhoneNumber(value)}}/> */}
          <div className='phone_box'>
            <span className='label_pb'>手机号</span>
            <Input allowClear maxLength={11} height={33} placeholder='请输入手机号' value={phoneNumber} onChange={(value)=>{setPhoneNumber(value)}}/>
          </div>
          <div className='phone_box vcode_box'>
            <span className='label_pb'>验证码</span>
            <Input allowClear height={33} placeholder='请输入验证码' value={msgCode} onChange={(value)=>{setMsgCode(value)}}/>
            {
              searchButton!==0?<div className="getvcode" type='outline'>{searchButton || '获取验证码'}</div>:
              <div className="getvcode" onClick={getMsgCode} type='outline'>{searchButton || '获取验证码'}</div>
            }
          </div>
        </div>
      <div className='surebtnn' onClick={()=>{
        setVisiblePhone(false)
        updatePhoneAjax({phone: phoneNumber,
          code: msgCode})
      }}>
        确认修改
      </div>
    </Modal>

    <Modal
      className="xzy_modal"
      title={null}
      visible={visibleWx}
      style={{
        width:'334px',
        height: '288px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 11px 0px rgba(191,191,191,0.37)',
        borderRadius: '20px'
      }}
      autoFocus={true}
      onCancel={() => setVisibleWx(false)}
      footer={null}
    >
      <div className='titss'>
        解绑微信
      </div>
      <div className='wx-qrcode'>
        {!qrcodeScene?.url ? <Spin loading={loading}></Spin>:<img className='qrcode' src={qrcodeScene.url} alt='二维码'/>}
      </div>
    </Modal>

    <VipDialog ref={VipDialogRef}/>

    </>
  );
}

export default Premium;
