

import React, { useState,useRef,useEffect,forwardRef,useImperativeHandle,useCallback } from 'react';
import { Message, Input, Button, Checkbox,Spin } from '@arco-design/web-react';
import { useLocation } from 'react-router-dom';
import { IconSafe, IconSearch, IconInfoCircle } from '@arco-design/web-react/icon';

import './Login.scss'
import LoginIcon from '../../assets/logo.png'
import PhoneIcon from '../../assets/phone.svg'
import closed from '../../assets/v2/imgs/closed.png'
import Phonelogin from '../../assets/phonelogin.svg'
import useIntervalAsync from '../hook/useIntervalAsync'
import {login as ajaxLogin,sendCode,getWxQrcode,getWxLogin,userInfo as getUser} from '../../api/all'

import UserServices from '../../assets/窄门用户服务协议V1.0.pdf'
import Privacy from '../../assets/窄门隐私条款V1.0.pdf'
import {
  useRecoilValue,
  useRecoilState
} from 'recoil';
import { userInfoState } from './store'

let pdf = {
  1:UserServices,
  2:Privacy
}
function setZoomByClassName(className,value) {
  var elements = document.getElementsByClassName(className);
  // 遍历每个元素并设置其CSS样式
  for (var i = 0; i < elements.length; i++) {
      // 设置CSS样式
      elements[i].style.zoom = value;
  }
}
const LoginDialog = forwardRef((props, ref) => {

  const location = useLocation()

  const [showDialog,setShowDialog] = useState(false)
  const [phoneNumber,setPhoneNumber] = useState('')
  const [msgCode,setMsgCode] = useState('')  
  const [isAgree,setIsAgree] = useState(false) 
  const [mode,setMode] = useState(1) 
  const [qrcodeScene,setQrcodeScene] = useState(null)
  const [loading, setLoading] = useState(false);
  const [userInfo,setUserInfo] = useRecoilState(userInfoState)
  
  useImperativeHandle(ref, (props) => ({
    setShowDialog
  }));

  const [searchButton,setSearchButton] = useState(0)
  const countDownTimer = useRef(null);

  useEffect(() => {
    var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if(windowHeight<900){
      setZoomByClassName('zoom',windowHeight/900)
      setZoomByClassName('newfixed',900/windowHeight)
    }
    return () => {
      clearTimeout(countDownTimer.current);
    };
  }, []);

  useEffect(()=>{
      if(searchButton===0){
          clearInterval(countDownTimer.current)
      }
  },[searchButton])

  useEffect(()=>{
    if(mode===0){
      setLoading(true)
      getWxQrcode().then((res)=>{
        setQrcodeScene(res.result)
        setLoading(false)
      })
    }else{
      setQrcodeScene(null)
    }
  },[mode])



  const updateState = useCallback(async () => {
    if (qrcodeScene && qrcodeScene.url && mode===0 ) {
      const response = await getWxLogin({
        scene:qrcodeScene?.scene
      })
      if (response.code === 0) {
        update(0)
        setQrcodeScene(null)
        Message.success("登录成功");
        localStorage.setItem("token", response.result);
        getuserAjax()
        if(location.pathname === '/login'){
          window.location=document.referrer;
        }else{
          window.location.reload(1)
        }
      }
    }
  }, [location.pathname, mode, qrcodeScene]);

  const getuserAjax = ()=>{
    getUser().then((res)=>{
      let user = res.result
      let datauser = [
        // {
        //   label: '用户名',
        //   value: user.name,
        // },
        {
          label: '用户ID',
          value: user.id,
        },
        {
          label: '手机号码',
          value: user.phone,
        },
        {
          label: '注册时间',
          value: user.created_at.replace('T',' '),
        },
      ];
      setUserInfo(user)
    })
  }

  const update  =  useIntervalAsync(updateState, 3000);

  const openPdf = (e,event)=> {
    event.preventDefault();
    window.open(pdf[e])
  }

  const login = ()=> {
    if (phoneNumber === "") {
      Message.error("请输入手机号码");
      return;
    }
    if (msgCode === "") {
      Message.error("请输入验证码");
      return;
    }
    if (!isAgree) {
      Message.error("请勾选协议");
      return;
    }
    let params = {
      phone: phoneNumber,
      code: msgCode
    };
    ajaxLogin(params).then(res => {
      if (res.code === 0) {
        Message.success("登录成功");
        localStorage.setItem("token", res.result);
        getuserAjax()
        if(location.pathname == '/login'){
          window.location=document.referrer;
        }else{
          window.location.reload(1)
        }
      }else{
        Message.error(res.message);
      }
    },error => {
        console.log("get request failed:",error);
    });
  }


  const getMsgCode = ()=> {
    if (phoneNumber === "") {
      Message.error("请输入手机号码");
      return;
    }
    if (phoneNumber.length !=11) {
      Message.error("请输入正确的手机号码");
      return;
    }
    let params = {
      phone: phoneNumber,
      type: "login"
    };
    sendCode(params).then(res => {
      if (res.code === 0) {
        countDown()
      }
    },error => {
        console.log("get request failed:",error);
    });
  }

  const countDown = (a,b)=>{
    setSearchButton(60)
    countDownTimer.current=setInterval(()=>{
      setSearchButton(c=>c-1)
    },1000)
  }

  return (
  <div style={{display:showDialog?'block':'none'}}>
    <div className="relative z-50" id="headlessui-dialog-:rdd:" role="dialog" aria-modal="true" data-headlessui-state="open"
      aria-labelledby="headlessui-dialog-title-:rdf:">
      {/* <div className="fixed inset-0 newfixed" aria-hidden="true"></div> */}
      <div className="fixed inset-0 flex items-center justify-center">
        <div style={{width:'334px',height:'346px',position:'relative'}} className="mx-auto rounded-3xl bg-primary-background shadow-2xl max-h-full overflow-hidden flex flex-col rounded-xl"
          id="headlessui-dialog-panel-:rde:" data-headlessui-state="open">
            <div className='mode-box'>
              <div className={mode?'wxlogin':'wxlogin check-mode'} onClick={()=>{setMode(0)}}>微信登录</div>
              <div className={mode?'check-mode':''}  onClick={()=>{setMode(1)}}>手机登录</div>
            </div>
            <div className='closed'>
              <img src={closed} onClick={()=>{setShowDialog(false)}} alt=''/>
            </div>
            {
              mode?<>
              <div className='login-info'>
              <div className='right'>
                <div className='input-box'>
                  {/* <Input prefix={<span>手机号</span>}  size='large' allowClear maxLength={11} height={46} placeholder='请输入手机号' value={phoneNumber} onChange={(value)=>{setPhoneNumber(value)}}/> */}
                  <div className='phone_box'>
                    <span className='label_pb'>手机号</span>
                    <Input allowClear maxLength={11} height={33} placeholder='请输入手机号' value={phoneNumber} onChange={(value)=>{setPhoneNumber(value)}}/>
                  </div>
                  <div className='phone_box vcode_box'>
                    <span className='label_pb'>验证码</span>
                    <Input allowClear height={33} placeholder='请输入验证码' value={msgCode} onChange={(value)=>{setMsgCode(value)}}/>
                    {
                      searchButton!==0?<div className="getvcode disabled_getcode" type='outline'>{searchButton || '获取验证码'}</div>:
                      <div className="getvcode" onClick={getMsgCode} type='outline'>{searchButton || '获取验证码'}</div>
                    }
                  </div>
                </div>
                <button onClick={()=>{login()}} className="login-btn">
                  登录
                </button>
              </div>
            </div>
              </>:<>
              <div className='wxchat'>
                    <div className='qrcode-box'>
                    {!qrcodeScene?.url ? <Spin loading={loading}></Spin>:<img className='qrcode' src={qrcodeScene.url} alt='二维码'/>}
                    </div>
                    <div className='tips'>
                    微信扫码登录
                    </div>
              </div>
              </>
            }
            <div className='xieyi'>
              <Checkbox checked={isAgree} onChange={(value)=>{setIsAgree(value)}}>
              登录即同意<span className='color-txt' onClick={(event)=>{openPdf(1,event)}}>《用户协议》</span><span className='color-txt' onClick={(event)=>{openPdf(2,event)}}>《隐私政策》</span>
              </Checkbox>
            </div>

        </div>
      </div>
    </div>
  </div>
  )
})

export default LoginDialog