
import React, { Component ,useState, useEffect} from 'react';
import './App.css';
import { NavLink } from "react-router-dom";
// import Aside from './pages/main/Aside'
import Aside from './pages/main/Aside/Index'
import Header from './pages/main/Header/Index'
import Router from './router';
import {
  RecoilRoot,
  useRecoilValue,
  useRecoilState,
} from 'recoil';
import { useLocation } from "react-router";

String.prototype.trim = function () {
  return this.replace(/(^\s*)|(\s*$)/g, '');
}

function setZoomByClassName(className,value) {
  var elements = document.getElementsByClassName(className);
  // 遍历每个元素并设置其CSS样式
  for (var i = 0; i < elements.length; i++) {
      // 设置CSS样式
      elements[i].style.zoom = value;
  }
}

function App() {
  const location = useLocation()

  useEffect(() => {
    document.getElementsByClassName('App')[0].style.backgroundColor = '#f8f8f8'
    var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if(windowHeight<900){
      setZoomByClassName('zoom',windowHeight/900)
      setZoomByClassName('newfixed',900/windowHeight)
    }
    return ()=>{
    }
  },[]);

  return (
    <RecoilRoot>
    <div className="App">
      {/* <div className="top-bar-box zoom" style={{width:location.pathname==='/home'? '260px':'100%'}}>
        <Header/>
      </div>  */}
      <Header/>
      <aside className='aside-box zoom'>
        <Aside/>
      </aside>
      <div className='zoom' style={{flex: '1',height: '100%',paddingTop:location.pathname==='/home'?'0':'60px'}}>
        <Router/>
      </div>
      {/* <iframe style={{width:"100%",height:"100%"}}src="http://localhost:8081/#/report-default"></iframe>
      <Router/> */}
    </div>
    </RecoilRoot>
  );
}

export default App;
