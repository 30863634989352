

import React, { useState,useRef,useEffect,forwardRef,useImperativeHandle,useCallback } from 'react';
import { Modal, Upload, Button, Checkbox,Spin } from '@arco-design/web-react';
import { IconPlusCircle,IconMinusCircle,IconSync } from '@arco-design/web-react/icon';

import Cropper from 'react-cropper';
import './UploadHead.scss'
import "./cropper.min.css";
import HeaderImg from '../../../assets/v2/imgs/head2x.png';

import {userInfo as getUser,orderList,updateUserInfo,getWxQrcode,uploadImg} from '../../../api/all'
import {
  useRecoilValue
} from 'recoil';
import { userInfoState} from '../store'
import axios from "axios";
axios.defaults.headers.common['token'] = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');;
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data; charset=utf-8';

function setZoomByClassName(className,value) {
  var elements = document.getElementsByClassName(className);
  // 遍历每个元素并设置其CSS样式
  for (var i = 0; i < elements.length; i++) {
      // 设置CSS样式
      elements[i].style.zoom = value;
  }
}

function base64ToBlob(base64Data) {
  const dataArr = base64Data.split(','); // 根据,来分隔
  const imageType = dataArr[0].match(/:(.*?);/)[1]; // 获取文件类型。使用正则捕获 image/jpeg
  const textData = window.atob(dataArr[1]); // 使用atob() 将base64 转为文本文件
  const arrayBuffer = new ArrayBuffer(textData.length); // 创建一个二进制数据缓冲区，可以理解为一个数组
  const uint8Array = new Uint8Array(arrayBuffer); // 创建一个类型化数组对象，可以理解为上面的数组的成员，给这个对象赋值就会放到上面的数组中。
  for (let i = 0; i < textData.length; i++) {
    uint8Array[i] = textData.charCodeAt(i); // 将文本文件转为UTF-16的ASCII, 放到类型化数组对象中
  }
  return [new Blob([arrayBuffer], {type: imageType}), imageType.slice(6)]; // 返回两个值，一个Blob对象，一个图片格式（如jpeg）
}

// 转为formData
function toFormData(base64Data) {
  const [imageBlob, imageType] = base64ToBlob(base64Data); // 获取处理好的Blob 和文件类型
  console.log(imageBlob,imageType)
  const formData = new FormData();
  formData.append('file', imageBlob, `${Date.now()}.${imageType}`); // 添加到表单，传入文件名
  return formData;
}

const UploadHead = forwardRef(({onChildEvent}, ref) => {

  const cropperRef = useRef(null)
  const userInfo = useRecoilValue(userInfoState)
  const [visible,setVisible] = useState(false)
  const [headerImg,setHeaderImg] = useState(userInfo?.head||HeaderImg)
  const [imgUrl,setImgUrl] = useState('')
  const [loading,setLoading] = useState(false)
 



  useImperativeHandle(ref, (props) => ({
    setVisible,
    setHeaderImg
  }));

  useEffect(() => {
    var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if(windowHeight<900){
      setZoomByClassName('zoom',windowHeight/900)
      setZoomByClassName('newfixed',900/windowHeight)
    }
    return () => {

    };
  }, []);


  useEffect(() => {
    if(cropperRef.current?.cropper){
      onCrop()
    }
  }, [cropperRef]);
  
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    setImgUrl(cropper.getCroppedCanvas().toDataURL())
  };

  const onZoom = (type) => {
    cropperRef.current?.cropper.zoom(type?0.1:-0.1)
  }

  const onRotate = () => {
    cropperRef.current?.cropper.rotate(90)
  }
  const uploadChange = (files) => {
    setHeaderImg(URL.createObjectURL(files[0].originFile))
    setLoading(false)
  }

  const beforeUpload = ()=>{
    setLoading(true)
    return true
  }

  const uploadImgAjax = ()=>{
    let formData = toFormData(imgUrl)
    // uploadImg(formData)
    let axi = axios
    axi.defaults.headers.post['Content-Type'] = 'multipart/form-data; charset=utf-8';


    axios.post('/chat/chatalls/upload_img', formData,{
      "Content-Type": 'multipart/form-data;charset=utf-8'
    }).then(function (response) {
      onChildEvent('http://chatalls.com/'+response.data.result)
    });


  }

  return (
    <Modal
      className="uphead_modal"
      title={null}
      visible={visible}
      style={{
        width:'334px',
        height: '378px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 11px 0px rgba(191,191,191,0.37)',
        borderRadius: '20px'
      }}
      autoFocus={true}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className="up-title">
        上传头像
      </div>
      <div className="cropper-box-wrap">
        <div className="cropper-box">
          {
            loading?<Spin size={50} className='upload-loading'/>:<Cropper
            className='cropper-img'
            src={headerImg}
            style={{ height: 180, width: 200 }}
            initialAspectRatio={1}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
          />
          }
        </div>
        <div className="img">
            <img src={imgUrl} alt=""/>
        </div>
      </div>
      <div className="cropper-action">
        <Upload listType="none" onChange={uploadChange} beforeUpload={beforeUpload} action='/'><div className='again-upload'>重新上传</div></Upload>
        <div className='cropper-action-icon'><IconMinusCircle onClick={()=>{onZoom(0)}}/><IconPlusCircle onClick={()=>{onZoom(1)}}/><IconSync onClick={()=>{onRotate(1)}}/></div>
      </div>
      <div className='btn_box_up'>
        <div onClick={()=>{setVisible(false)}} className="cancel_btn">取消</div>
        <div onClick={()=>{
          uploadImgAjax()
        }} className="sure_btn">确认</div>
      </div>
    </Modal>
  )
})

export default UploadHead