

import React, { useState,useRef,useEffect,forwardRef,useImperativeHandle,useCallback } from 'react';
import { Modal, Input, Button, Checkbox,Spin } from '@arco-design/web-react';

import './DelConfirm.scss'

function setZoomByClassName(className,value) {
    var elements = document.getElementsByClassName(className);
    // 遍历每个元素并设置其CSS样式
    for (var i = 0; i < elements.length; i++) {
        // 设置CSS样式
        elements[i].style.zoom = value;
    }
}

const DelConfirm = forwardRef((props, ref) => {
    const [visible,setVisible] = useState(false)
    useImperativeHandle(ref, (props) => ({
        setVisible
    }));
    useEffect(() => {
        var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if(windowHeight<900){
            setZoomByClassName('zoom',windowHeight/900)
            setZoomByClassName('newfixed',900/windowHeight)
        }
        return () => {

        };
    }, []);
    return (
        <>
        <Modal
        mask={false}
        className="delmodel"
        title={null}
        visible={visible}
        style={{
            width:'412px',
            height: '214px',
            background: '#FFFFFF',
            borderRadius: '4px',
            boxShadow: '0px 0px 11px 0px rgba(191,191,191,0.37)'
        }}
        autoFocus={true}
        closable={false}
        onCancel={() => setVisible(false)}
        footer={null}
        >
            <div className="del_model">
                <div className="tit-txt">提示</div>
                <div className='cont-txt'>确认删除照片头像</div>
                <div className="btn_box">
                    <div onClick={()=>{setVisible(false)}} className="cancel_btn">取消</div>
                    <div onClick={()=>{setVisible(false)}} className="sure_btn">确认</div>
                </div>
            </div>
        </Modal>
        {/* <div className="select_btn" onClick={()=>{setVisible(true)}}>开启</div> */}
        </>
    )
})

export default DelConfirm